import { msalInstance } from '../api/auth';

interface ComponentData {
    component_name: string;
    component_title: string;
    component_type: string;
    interaction_type: string;
    interaction_value: string;
    interaction_url?: string;
}

/**
 * Checks if the user is authenticated and token is valid
 * Returns true if authenticated, false otherwise and triggers page refresh
 */
const checkAuthentication = (): boolean => {
    const account = msalInstance.getActiveAccount();
    
    // If no active account, refresh the page
    if (!account) {
        console.warn('No active MSAL account found, refreshing page');
        window.location.reload();
        return false;
    }

    // Check token expiration
    const idTokenClaims = account.idTokenClaims as { exp?: number };
    if (idTokenClaims?.exp && Date.now() >= idTokenClaims.exp * 1000) {
        console.warn('MSAL token expired, refreshing page');
        window.location.reload();
        return false;
    }

    return true;
};

/**
 * Pushes an event to Google Analytics dataLayer with user authentication check
 */
export const pushToDataLayer = (eventName: string, componentData: ComponentData) => {
    if (!checkAuthentication()) return;

    const account = msalInstance.getActiveAccount()!;

    // Clear any previous component data
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ component: null });

    // Push the event with user ID
    window.dataLayer.push({
        event: eventName,
        user: {
            id: account.homeAccountId,
            loginStatus: 'logged in'
        },
        component: {
            ...componentData,
            interaction_url: componentData.interaction_url || 'n/a'
        }
    });
};

/**
 * Helper function to track component interactions
 */
export const trackComponentInteraction = (
    componentName: string,
    componentTitle: string,
    componentType: string,
    interactionType: string,
    interactionValue: string,
    interactionUrl: string = 'n/a'
) => {
    pushToDataLayer('component_interaction', {
        component_name: componentName,
        component_title: componentTitle,
        component_type: componentType,
        interaction_type: interactionType,
        interaction_value: interactionValue,
        interaction_url: interactionUrl
    });
};

/**
 * Helper function to track page initialization with authentication check
 */
export const trackPageInit = (pageId: string, pageType: string, pageTitle: string) => {
    if (!checkAuthentication()) return;

    const account = msalInstance.getActiveAccount()!;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'page_init',
        user: {
            id: account.homeAccountId,
            loginStatus: 'logged in'
        },
        page: {
            id: pageId,
            type: pageType,
            url: window.location.href,
            title: pageTitle
        }
    });
}; 