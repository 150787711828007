import { WeatherSunny16Regular, WeatherMoon16Regular } from "@fluentui/react-icons";
import styles from "./ThemeToggleButton.module.css";
import { trackComponentInteraction } from "../../utils/analytics";

interface Props {
    theme: string;
    toggleTheme: () => void;
}

export const ThemeToggleButton = ({ theme, toggleTheme }: Props) => {
    const isDarkTheme = theme === 'dark';
    const buttonClass = `${styles.themeToggleButton} ${isDarkTheme ? styles.dark : ''}`;
    const sliderClass = `${styles.slider} ${isDarkTheme ? styles.darkSlider : ''}`;

    const handleToggle = () => {
        const newTheme = isDarkTheme ? 'light' : 'dark';
        
        trackComponentInteraction(
            'dark mode toggle',
            'dark mode toggle',
            'button',
            'click',
            `toggle:${newTheme}`
        );

        // Call the original toggleTheme function
        toggleTheme();
    };

    return (
        <button onClick={handleToggle} className={buttonClass}>
            <WeatherSunny16Regular className={styles.sunIcon} />
            <WeatherMoon16Regular className={styles.moonIcon} />
            <div className={sliderClass}></div>
        </button>
    );
};