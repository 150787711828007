import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalConfig, LoginRequest } from "../config/authconfig";

export const msalInstance = new PublicClientApplication(MsalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account){        
        // @ts-ignore
        msalInstance.setActiveAccount(event.payload?.account);
    }
    if (event.eventType === EventType.LOGIN_FAILURE) console.log(JSON.stringify(event));
});

export const acquireAccessToken = async (msalInstance: any) => {
    const activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount && accounts.length === 0) {
        // Only refresh if we haven't already
        if (!sessionStorage.getItem('auth_refresh_in_progress')) {
            sessionStorage.setItem('auth_refresh_in_progress', 'true');
            window.location.reload();
        }
        throw new Error('No active account');
    }

    // Clear the refresh flag if we have an active account
    sessionStorage.removeItem('auth_refresh_in_progress');

    const authResult = await msalInstance.acquireTokenSilent({
        scopes: LoginRequest.scopes,
        account: activeAccount || accounts[0]
    });

    return authResult.accessToken;
};

export const acquireBearerToken = async (msalInstance: any) => {
    const accessToken = await acquireAccessToken(msalInstance);
    return "Bearer " + accessToken;
};