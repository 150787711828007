import { Cards } from "../api/models";

export const landingcard: { [key: string]: Cards } = {
    "SafeGPT": {
        "title": "Safe Co-op GPT",
        "description": "Safe Co-op GPT is a place for you to safely learn how to use a generative AI tool.<br /> <br />You can use it without the risk of exposing personal or Fonterra sensitive data. Check out the <a href=https://fonterra.sharepoint.com/sites/GenerativeAIatFonterra/SitePages/Co-op-GPT-Training.aspx target='_blank' rel='noopener noreferrer'>user guide</a> for more information.",
        "backDescription": "<ul><li>Max input character count of 40,000​.</li><br /><li>Only aware of information up to October 2023​.</li><br /><li>Not aware of Fonterra specific information and therefore can’t answer Fonterra specific questions.</li><br /><li>LLM learning is turned off.</li></ul>"
    },
    "CoopGPTPPL": {
        "title": "PPL for Global Policies & Standards",
        "description": "Ask Co-op GPT questions about our Global Polices & Standards. <br /><br /> Verify the accuracy & completeness of any information using the cited PPL document links.",
        "backDescription": "<ul><li>Global policies are principles-based, high level statements of intent including our beliefs, commitments and expectations of our people.</li><br /><li>Global standards are Fonterra-wide concise, unambiguous, mandatory actions or rules that give effect to the overarching policy/s.</li></ul>"
    },
    "CoopGPTFDP": {
        "title": "Co-op GPT for Data Products",
        "description": "Co-op GPT for Data Products is undergoing development. <br /><br /> Stay tuned for further updates.",
        "backDescription": "This will enable Co-op GPT to query specific data products within Data Hub that you are authorised to access, to assist with answering your queries."
    },
    "ImageGeneration": {
        "title": "AI Image Generation",
        "description": "Create custom images using AI technology. <br /><br /> Preview version.",
        "backDescription": "<ul><li>Generate unique images from text descriptions</li></ul>"
    },
    // ... other card details
}

export const previewFeatureSuffix: string = " (coming soon)"